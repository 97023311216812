var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('Heading',{attrs:{"title":_vm.$t('signup.TITLE')}}),_c('v-flex',{attrs:{"xs12":"","sm6":"","offset-sm3":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"name","name":"name","label":_vm.$t('signup.NAME'),"error":errors.length > 0,"error-messages":errors[0],"autocomplete":"off"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-flex',[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"email","name":"email","type":"email","label":_vm.$t('signup.EMAIL'),"error":errors.length > 0,"error-messages":errors[0],"autocomplete":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-flex',[_c('ValidationProvider',{attrs:{"rules":"required|min:5","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"id":"password","name":"password","type":"password","label":_vm.$t('signup.PASSWORD'),"error":errors.length > 0,"error-messages":errors[0],"autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-flex',[_c('ValidationProvider',{attrs:{"rules":"required|min:5|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"confirmPassword","name":"confirmPassword","type":"password","label":_vm.$t('signup.CONFIRM_PASSWORD'),"error":errors.length > 0,"error-messages":errors[0],"autocomplete":"off"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1),_c('v-flex',{attrs:{"text-xs-center":"","mt-5":""}},[_c('SubmitButton',{attrs:{"buttonText":_vm.$t('signup.SIGN_ME_UP')}})],1)],1)],1)]}}])})],1),_c('ErrorMessage')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }